<template>
  <v-container
    class="pa-0 container--fluid"
  >
    <v-card
      class="ma-0"
    >
      <div class="table-filters">
        <div class="row">
          <div class="col">
            <v-subheader class="input-label">
              Rental Status
            </v-subheader>
            <v-select
              v-model="filterStatus"
              :items="[
                { name: 'Open', value: 'open' },
                { name: 'Closed', value: 'closed' },
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col">
            <v-subheader class="input-label">
              Rental ID
            </v-subheader>
            <v-text-field
              v-model="filterId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col">
            <v-subheader class="input-label">
              Leaser ID
            </v-subheader>
            <v-text-field
              v-model="filterLeaserId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col">
            <v-subheader class="input-label">
              Renter ID
            </v-subheader>
            <v-text-field
              v-model="filterRenterId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col">
            <v-subheader class="input-label">
              Object ID
            </v-subheader>
            <v-text-field
              v-model="filterObjectId"
              type="number"
              single-line
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col">
            <v-subheader class="input-label">
              Start Date
            </v-subheader>
            <v-menu
              ref="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterStartDate"
                  single-line
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="filterStartDate"
              />
            </v-menu>
          </div>
          <div class="col">
            <v-subheader class="input-label">
              End Date
            </v-subheader>
            <v-menu
              ref="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterEndDate"
                  single-line
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="filterEndDate"
              />
            </v-menu>
          </div>
        </div>
        <div class="table-filters__controls">
          <v-btn
            color="red"
            medium
            @click="clearFilters"
          >
            Reset
          </v-btn>
        </div>
      </div>
      <div
        class="table-wrapper px-6 py-3"
      >
        <v-data-table
          ref="objecttable"
          v-model="selected"
          :page.sync="currentPage"
          :headers="objectHeaders"
          :items="objects"
          :loading="loading"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [1, 2, 10, 20, 50, 100]
          }"
          :items-per-page.sync="perPage"
          :fixed-header="true"
          class="fixed-columns-table"
          @page-count="pageCount"
        >
          <template v-slot:no-data>
            No data
          </template>
          <template v-slot:item.leaserId="{ item }">
            <router-link
              v-slot="{ href }"
              :to="{ name: 'Edit User', params: { id: item.leaserId } }"
              class="link__hidden full-cell"
              target="_blank"
            >
              <a
                :href="href"
                target="_blank"
              >
                <span>
                  {{ item.leaserId }}
                </span>
              </a>
            </router-link>
          </template>
          <template v-slot:item.renterId="{ item }">
            <router-link
              v-slot="{ href }"
              :to="{ name: 'Edit User', params: { id: item.renterId } }"
              class="link__hidden full-cell"
              target="_blank"
            >
              <a
                :href="href"
                target="_blank"
              >
                <span>
                  {{ item.renterId }}
                </span>
              </a>
            </router-link>
          </template>
          <template v-slot:item.objectId="{ item }">
            <router-link
              v-slot="{ href }"
              :to="{ name: 'Edit Object', params: { id: item.objectId } }"
              class="link__hidden full-cell"
              target="_blank"
            >
              <a
                :href="href"
                target="_blank"
              >
                <span>
                  {{ item.objectId }}
                </span>
              </a>
            </router-link>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="table-actions">
              <v-btn
                color="teal lighten-2"
                elevation="0"
                class="action-edit"
                @click="objectAction('edit object', item)"
              >
                <v-icon left>
                  mdi-eye
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="currentPage"
              class="table-pagination"
              :length="pageCount"
              total-visible="7"
            />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import axios from '@/plugins/api'

  export default {
    data () {
      return {
        currentPage: 1,
        pageCount: 0,
        perPage: 10,
        objects: [],
        tableObjects: null,
        selected: [],
        filterId: '',
        filterLeaserId: '',
        filterRenterId: '',
        filterObjectId: '',
        filterStartDate: null,
        filterEndDate: null,
        filterStatus: null,
        appliedFilters: {},
        objectsTotal: 0,
        objectHeaders: [
          { text: 'Rental ID', value: 'id', align: 'center', width: '100px' },
          { text: 'Rental Status', value: 'rentalStatus', align: 'center', width: '100px' },
          { text: 'Leaser ID', value: 'leaserId', align: 'center', width: '100px' },
          { text: 'Renter ID', value: 'renterId', align: 'center', width: '100px' },
          { text: 'Object ID', value: 'objectId', align: 'center', width: '100px' },
          { text: 'Object Type', value: 'objectType', align: 'center', width: '100px' },
          { text: 'Total Requests', value: 'totalRequests', align: 'center', width: '100px' },
          { text: 'Start Date', value: 'startDate', width: '120px' },
          { text: 'End Date', value: 'endDate', width: '120px' },
          { text: 'Total Rental Days', value: 'totalRentalDays', align: 'center', width: '100px' },
          { text: 'Rental Fee', value: 'rentalFee', align: 'center', width: '100px' },
          { text: 'Insurance Fee', value: 'insuranceFee', align: 'center', width: '100px' },
          { text: 'Gross Rental Fee', value: 'grossRentalFee', align: 'center', width: '100px' },
          { text: 'Net Rental Fee', value: 'netRentalFee', align: 'center', width: '100px' },
          { text: 'Discount Code', value: 'discountCode', align: 'center', width: '120px' },
          { text: 'Discount Calculated', value: 'discountCalculated', align: 'center', width: '100px' },
          { text: 'Action', value: 'action', align: 'center', width: '110px' },
        ],
        localWidths: [],
        loading: false,
      }
    },

    watch: {
      filterId: {
        handler () { this.loadRentals() },
      },
      filterLeaserId: {
        handler () { this.loadRentals() },
      },
      filterRenterId: {
        handler () { this.loadRentals() },
      },
      filterObjectId: {
        handler () { this.loadRentals() },
      },
      filterStartDate: {
        handler () { this.loadRentals() },
      },
      filterEndDate: {
        handler () { this.loadRentals() },
      },
      filterStatus: {
        handler () { this.loadRentals() },
      },
      perPage: {
        handler () { this.loadRentals() },
      },
      currentPage: {
        handler () { this.loadRentals() },
      },
    },

    created () {
      this.loadRentals()
    },

    methods: {
      loadRentals () {
        this.loading = true
        const filters = {
          status: this.filterStatus,
          rentalId: this.filterId,
          leaserId: this.filterLeaserId,
          renterId: this.filterRenterId,
          objectId: this.filterObjectId,
          startDate: this.filterStartDate,
          endDate: this.filterEndDate,
          page: this.currentPage,
          perPage: this.perPage,
        }

        const _this = this
        axios.get('/admin/rentals', { params: filters })
          .then(response => {
            const data = response.data
            _this.objects = data.rentals.map(el => {
              return {
                id: el.id,
                rentalStatus: el.rentalStatus,
                leaserId: el.leaser.id,
                renterId: el.renter.id,
                objectId: el.product.id,
                objectType: el.product.type.name,
                totalRequests: el.totalRequests,
                startDate: moment(el.startDate).format('YYYY MMM DD'),
                endDate: moment(el.endDate).format('YYYY MMM DD'),
                totalRentalDays: el.totalRentalDays,
                rentalFee: el.rentalFee,
                insuranceFee: el.insuranceFee,
                grossRentalFee: el.rentalGrossFee,
                netRentalFee: el.rentalFeeNet,
                discountCode: el.discountCode,
                discountCalculated: Number(el.discount) + '%',
              }
            })
            _this.pageCount = Math.ceil(Number(data.total) / _this.perPage)
            _this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      },
      objectAction (val, object) {
        if (val.toLowerCase() === 'edit object') {
          this.$router.push(`rentals/${object.id}`)
        }
      },
      clearFilters () {
        this.filterId = ''
        this.filterLeaserId = ''
        this.filterRenterId = ''
        this.filterObjectId = ''
        this.filterStartDate = null
        this.filterEndDate = null
        this.filterStatus = null
      },
    },
  }
</script>

<style lang="scss">
.full-cell {
  display: grid;
  place-content: center;
  width: calc(100% + 16px);
  height: 100%;
  margin: 0 -8px;
}
</style>
